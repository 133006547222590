<template>
  <div
    v-if="isShowing"
    ref="interactElement"
    :class="[
      {
        isAnimating: isInteractAnimating,
        isCurrent: isCurrent,
      },
    ]"
    class="card"
    :style="{ transform: transformString, color: '#000' }"
    @click="mobileFix"
  >
    <div
      class="card-container"
      :style="{backgroundColor: `${card.bgColor}20`}"
      style="border-radius: 12px;"
    >
      <h6 class="uppertext">
        {{ card.action }}
      </h6>
      <h6 class="cardTitle">
        {{ card.question }}
      </h6>
      <div>
        <div class="logo-container">
          <h3
            class="lowertext"
            style="width: 80px;"
          >
            <img :src="card.image[0].url" />
          </h3>
        </div>
        <div class="card-count">
          {{ card.number }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import interact from "interact.js";
import Cookies from "js-cookie";

const GO_UP = "goUp";
const ACCEPT_CARD = "cardAccepted";
const REJECT_CARD = "cardRejected";
const SKIP_CARD = "cardSkipped";

export default {
  static: {
    interactMaxRotation: 15,
    interactOutOfSightXCoordinate: window.innerWidth + 200,
    interactOutOfSightYCoordinate: window.innerHeight + 300,
    interactYThreshold: 150,
    interactXThreshold: 100,
  },

  props: {
    bgColor: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      required: true,
    },
    card: {
      type: Object,
      required: true,
    },
    isCurrent: {
      type: Boolean,
      required: true,
    },
  },

  data () {
    return {
      isShowing: true,
      isInteractAnimating: true,
      isInteractDragged: null,
      interactPosition: {
        x: 0,
        y: 0,
        rotation: 0,
      },
    };
  },

  computed: {
    isMobile () {
      return Cookies.get("mobile") === "false" ? false : true;
    },
    transformString () {
      if (!this.isInteractAnimating || this.isInteractDragged) {
        const { x, y, rotation } = this.interactPosition;
        return `translate3D(${x}px, ${y}px, 0) rotate(${rotation}deg)`;
      }

      return null;
    },
  },

  mounted () {
    const element = this.$refs.interactElement;

    interact(element).draggable({
      onstart: () => {
        this.isInteractAnimating = false;
      },

      onmove: (event) => {
        const { interactMaxRotation, interactXThreshold } = this.$options.static;
        const x = this.interactPosition.x + event.dx;
        const y = this.interactPosition.y + event.dy;

        let rotation = interactMaxRotation * (x / interactXThreshold);

        if (rotation > interactMaxRotation) rotation = interactMaxRotation;
        else if (rotation < -interactMaxRotation) rotation = -interactMaxRotation;

        this.interactSetPosition({ x, y, rotation });
      },

      onend: () => {
        const { x, y } = this.interactPosition;
        const { interactXThreshold, interactYThreshold } = this.$options.static;
        this.isInteractAnimating = true;

        if (x > interactXThreshold) this.playCard(ACCEPT_CARD);
        else if (x < -interactXThreshold) this.playCard(REJECT_CARD);
        else if (y > interactYThreshold) this.playCard(SKIP_CARD);
        else if (y < -interactYThreshold + 150) this.playCard(GO_UP);
        else this.resetCardPosition();
      },
    });
    this.$store.dispatch("getHeight");
  },

  beforeDestroy () {
    interact(this.$refs.interactElement).unset();
  },

  methods: {
    mobileFix () {
      if (!this.isInteractDragged && !this.isMobile) {
        const actArr = [ACCEPT_CARD, GO_UP, REJECT_CARD, SKIP_CARD];
        const rand = actArr[Math.floor(Math.random() * actArr.length)];
        this.playCard(rand);
      }
    },
    hideCard () {
      setTimeout(() => {
        //this.isShowing = false;
        this.$emit("hideCard", this.card);
      }, 300);
    },

    playCard (interaction) {
      this.$emit("nextRound");
      const {
        interactOutOfSightXCoordinate,
        interactOutOfSightYCoordinate,
        interactMaxRotation,
      } = this.$options.static;

      this.interactUnsetElement();

      switch (interaction) {
      case GO_UP:
        this.interactSetPosition({
          y: -interactOutOfSightYCoordinate,
          rotation: interactMaxRotation,
        });
        this.$emit(GO_UP);
        break;
      case ACCEPT_CARD:
        this.interactSetPosition({
          x: interactOutOfSightXCoordinate,
          rotation: interactMaxRotation,
        });
        this.$emit(ACCEPT_CARD);
        break;
      case REJECT_CARD:
        this.interactSetPosition({
          x: -interactOutOfSightXCoordinate,
          rotation: -interactMaxRotation,
        });
        this.$emit(REJECT_CARD);
        break;
      case SKIP_CARD:
        this.interactSetPosition({
          y: interactOutOfSightYCoordinate,
        });
        this.$emit(SKIP_CARD);
        break;
      }

      this.hideCard();
    },

    interactSetPosition (coordinates) {
      const { x = 0, y = 0, rotation = 0 } = coordinates;
      this.interactPosition = { x, y, rotation };
    },

    interactUnsetElement () {
      interact(this.$refs.interactElement).unset();
      this.isInteractDragged = true;
    },

    resetCardPosition () {
      this.interactSetPosition({ x: 0, y: 0, rotation: 0 });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/main.scss";

$cardsTotal: 3;
$cardsWidth: 300px;
$cardsPositionOffset: 55vh * 0.06;
$cardsScaleOffset: 0.08;
$defaultTranslation: $cardsPositionOffset * $cardsTotal;
$defaultScale: 1 - ($cardsScaleOffset * $cardsTotal);
$fs-card-title: 1.125em;
@mixin maintain-ratio($ratio: 1 1) {
  $width: 100%;
  $height: percentage(nth($ratio, 2) / nth($ratio, 1));

  width: $width;
  height: 0;
}
.card {
  @include card();
  @include absolute(0);
  //@include sizing(100% 80vw);
  @include maintain-ratio(2 3);
  @include flex-center();

  .uppertext {
    text-align: center;
    width: 100%;
    box-sizing: border-box;
  }

  .lowertext {
    text-align: center;
    width: 100%;
    box-sizing: border-box;
  }

  // @include after() {
  //   @include sizing(21px 3px);
  //   @include absolute(right 0 bottom 11px left 0);

  //   margin: auto;
  //   border-radius: 100px;
  //   background: rgba($c-black, 0.3);
  // }

  display: flex;
  max-height: 500px;
  width: 342px;
  margin: auto;
  font-size: $fs-h2;
  font-weight: $fw-bold;
  color: $c-white;

  opacity: 0;
  transform: translateY($defaultTranslation) scale($defaultScale);
  transform-origin: 50%, 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  user-select: none;
  pointer-events: none;
  will-change: transform, opacity;

  height: 100vw;

  &.isCurrent {
    pointer-events: auto;
  }

  &.isAnimating {
    transition: transform 2s $ease-out-back;
  }
}

.cardTitle {
  margin: 0 0 15px;
  text-align: center;
  width: 100%;
  font-size: 1.5em;
  font-weight: 500;
}

@for $i from 1 through $cardsTotal {
  $index: $i - 1;
  $translation: $cardsPositionOffset * $index;
  $scale: 1 - ($cardsScaleOffset * $index);

  .card:nth-child(#{$i}) {
    z-index: $cardsTotal - $index;
    opacity: 1;
    transform: translateY($translation) scale($scale);

    @if $i == 3 {
      //color: $c-red-25;
      background-color: $c-red-25;
    } @else if $i == 2 {
      //color: $c-red-50;
      background-color: $c-red-50;
    }

    @if $i != 1 {
      background-image: none;

      @include after() {
        @include sizing(0 0);
      }
    }
  }
}

.black {
  background-color: $black !important;
  color: $white;
}

.green {
  background-color: $card-green !important;
  color: $black;
}

.blue {
  background-color: $card-blue !important;
  color: $black;
}

.yellow {
  background-color: $card-orange !important;
  color: $black;
}

.red {
  background-color: $card-red !important;
  color: $black;
}

.cardsIpad {
  h6 {
    font-size: 1.1rem !important;
  }
}

@media screen and (min-width: 100px) {
  .lowertext {
    font-size: 1.2rem !important;
  }

  .playername {
    font-size: 1.2rem !important;
  }

  .uppertext {
    font-size: 1.2rem !important;
  }
}

@media screen and (min-width: 1251px) and (max-width: 1500px) {
  .card .lowertext {
    font-size: 1.2rem !important;
  }

  .playername {
    font-size:1.2rem !important;
  }

  .uppertext {
    font-size: 1.2rem !important;
  }
}

@media screen and (max-width: 1250px) {
  .lowertext {
    font-size: 1.2rem !important;
  }

  .playername {
    font-size: 1.2rem !important;
  }

  .uppertext {
    font-size: 1.2rem !important;
  }
}

.card-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
}

.logo-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.card-count {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 100;
  opacity: 0.80;
}

</style>
