<template>
  <div v-if="cards">
    <b-col
      v-if="cards && cards.length > 0 && players.length > 0"
      class="text-center"
      cols="12"
    >
      <span class="playername">{{ players[currentPlayer] }}</span>
    </b-col>
    <b-col
      v-if="gameType == 'standard'"
      lg="3"
      offset-lg="4"
    >
      <div
        class="hubu-wish fixTopMargin"
        :class="['text-red']"
      >
        <p v-if="quoteStandard">
          {{ quoteStandard.text }}
        </p>
        <b-button
          v-if="!$store.getters.loggedIn"
          variant="outline-red"
          :href="appText('Buy_url')"
        >
          {{ $t("auth.register") }}
        </b-button>
      </div>

      <GameCardsStack
        v-if="selectedDecks"
        :key="renderkey"
        :cards="cards"
        @hideCard="removeCardFromDeck"
        @nextRound="nextRound"
      />
    </b-col>
    <b-row>
      <b-col
        v-if="!isMobile"
        lg="3"
        offset-lg="1"
        class="arrow"
      >
        <div
          v-if="false"
        >
          <a
            href="javascript:void(0);"
          >
            <font-awesome-icon
              class
              :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
            />
            {{ $t("level") }} {{ $route.params.deck }}
          </a>
        </div>
      </b-col>
      <b-col
        v-if="gameType == 'deck'"
        lg="3"
      >
        <div
          class="hubu-wish"
          :class="[
            'text-' + deck.palette,
            { 'hubu-wish-mobile': isMobile, 'hubu-wish-iPad': iPad },
          ]"
        >
          <p v-if="quote">
            {{ quote.text }}
          </p>
          <b-button
            v-if="!$store.getters.loggedIn"
            :variant="'outline-' + deck.palette"
            :href="appText('Buy_url')"
          >
            {{ $t("auth.register") }}
          </b-button>
        </div>
        <GameCardsStack
          :key="renderkey"
          :cards="cards"
          :bg-color="deck.bg_color"
          :text-color="deck.text_color"
          @hideCard="removeCardFromDeck"
          @nextRound="nextRound"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FixHeight from "../Helpers/FixHeight";
import GameCardsStack from "./GameCardsStack";
import Shuffle from "../Helpers/Shuffle";
import Cookies from "js-cookie";

export default {
  name: "GameStage",
  components: {
    GameCardsStack,
  },
  data () {
    const players = Shuffle(Cookies.get("players") ? JSON.parse(Cookies.get("players")) : []);
    return {
      height: FixHeight(),
      wink: this.$store.getters.getWink,
      quotes: this.$store.getters.getQuotes,
      quoteNo: 0,
      players,
      currentPlayer: 0,
      quoteStandard: null,
      renderkey: 0,
      color: ["red", "blue", "green", "yellow"],
      cards: null,
    };
  },
  computed: {
    winkTop () {
      if (this.isMobile) {
        return 85;
      }

      return 65;
    },
    iPad () {
      return window.screen.width / window.screen.height > 0.65 &&
        window.screen.width / window.screen.height < 0.9
        ? true
        : false;
    },
    Height: {
      set (v) {
        this.height = v;
      },
      get () {
        return this.height;
      },
    },
    isMobile () {
      return Cookies.get("mobile") === "false" ? false : true;
    },
    quote: {
      get () {
        return this.quotes?.[this.quoteNo] || null;
      },
      set () {},
    },
    gameType: {
      get () {
        return this.$store.getters.getGameType;
      },
      set (v) {
        this.$store.commit("SET_GAME_TYPE", v);
      },
    },
    selectedDecks: {
      get () {
        return this.$store.getters.getSelectedDecks;
      },
      set (v) {
        this.$store.dispatch("setSelectedDecks", v);
      },
    },
    deck () {
      return this.getInitialDeck();
    },
    stackeddeck () {
      return this.$store.getters.getStackedDeck;
    },
    loggedIn () {
      return this.$store.getters.loggedIn;
    },
  },
  async beforeCreate () {
    await this.$store.dispatch("getDecks");
  },
  async mounted () {
    if (!this.$store.getters.getCardsFromSelectedDeckAndTemplates) {
      await this.$store.dispatch("setSelectedDecksAndTemplates", { 1: "standard" });
    }
    this.Height = FixHeight();
    window.addEventListener("resize", this.resizeHandler);

    this.cards = this.$store.getters.getCardsFromSelectedDeckAndTemplates ? [...this.$store.getters.getCardsFromSelectedDeckAndTemplates]: null;
  },

  methods: {
    getInitialDeck () {
      const deck = this.$store.getters.getSelectedDecksAndTemplates ? Object.keys(this.$store.getters.getSelectedDecksAndTemplates)[0] : null;
      return this.$store.getters.getDeck(deck);
    },
    resizeHandler () {
      this.Height = FixHeight();
    },
    appText (text) {
      return typeof this.$store.getters.getAppTexts[text] == "object"
        ? this.$store.getters.getAppTexts[text].content
        : text;
    },

    showWinkCard () {
      if (this.gameType == "deck") {
        for (let i = 0; i < this.cards.length; i++) {
          if (this.cards[i].winks) {
            let first = this.cards[0];
            this.cards[0] = this.cards[i];
            this.cards[i] = first;
            this.forceRerender();
            break;
          }
        }
      } else {
        for (let i = 0; i < this.selectedDecks.length; i++) {
          if (this.selectedDecks[i].winks) {
            let first = this.selectedDecks[0];
            this.selectedDecks[0] = this.selectedDecks[i];
            this.selectedDecks[i] = first;
            this.forceRerender();
            break;
          }
        }
      }
    },

    //Wink cards duplicate
    duplicateCardsByDecks (v) {
      v.forEach((val) => {
        v.push(val);
      });
      v = Shuffle(v);
      return v;
    },
    duplicateCards (v) {
      if (typeof v.duplicated == "undefined") {
        v.cards.forEach((val) => {
          v.cards.push(val);
        });
        v.duplicated = true;
      }
      v.cards = Shuffle(v.cards);
      return v;
    },

    nextRound () {
      if (this.currentPlayer == this.players.length - 1) {
        this.currentPlayer = 0;
      } else {
        this.currentPlayer += 1;
      }
    },
    forceRerender () {
      this.renderkey += 1;
    },
    removeCardFromDeck () {
      this.cards.shift();
      this.forceRerender();
      //this.visibleCards.shift();
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/buttons.scss";

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  text-align: center;
}

.arrow {
  margin-top: 20%;

  a {
    font-size: 2vw;
    color: $black;
  }

  a:hover {
    text-decoration: none;
    color: $black;
  }
}

.hubu-wish {
  position: absolute;
  top: 50%;
  //left: 30%;
  padding-left: 6vw;
  text-align: center;
  width: 100%;
  height: 90%;
}

.hubu-wish-mobile {
  padding-left: 0px;
  width: calc(100% - 30px) !important;
  height: auto;
  padding: 20%;
  margin-top: 15%;
  box-sizing: border-box;
}

.hubu-wish-iPad {
  padding: 25% !important;
  margin-top: 5% !important;
}

.text-red {
  color: $red;
}

.text-yellow {
  color: $orange;
}

.text-blue {
  color: $blue;
}

.text-green {
  color: $green;
}

.fixTopMargin {
  margin-top: 20%;
}

.bottom-arrows {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 9999;

  .arrow {
    width: 50%;
    padding: 15px;
    box-sizing: border-box;
    float: left;

    a {
      font-size: 4vw;
      color: $black;
    }

    a:hover {
      text-decoration: none;
      color: $black;
    }
  }
}

.wink-btn {
  position: absolute;
  left: 47%;
  z-index: 10000;
  padding-bottom: 25px;
  img {
    width: 80px;
  }
}

.isMobile.wink-btn {
  left: 40% !important;
  padding-bottom: 5px !important;
}

.isiPad.wink-btn {
  left: 44% !important;
  padding-bottom: 5px !important;
}

@media screen and (min-width: 100px) {
  .playername {
    font-size: 1.5rem !important;
  }
}

@media screen and (min-width: 1251px) and (max-width: 1500px) {
  .playername {
    font-size: 1.2rem !important;
  }
}

@media screen and (max-width: 1250px) {
  .playername {
    font-size: 1rem !important;
  }
}
</style>
