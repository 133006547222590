<template>
  <div
    class="cards"
    :class="{
      cardsMobile: cardsMobile,
      cardsIpad: iPad
    }"
  >
    <GameCard
      v-for="(card, index) in cards.filter(card => card.enabled).slice(0, 5)"
      :key="index"
      :card="card"
      :bg-color="card.bgColor"
      :text-color="card.textColor"
      :palette="card.palette"
      :is-current="true"
      @cardAccepted="$emit('cardAccepted')"
      @cardRejected="$emit('cardRejected')"
      @cardSkipped="$emit('cardSkipped')"
      @goUp="$emit('goUp')"
      @nextRound="$emit('nextRound')"
      @hideCard="$emit('hideCard')"
    />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import GameCard from "./GameCard";

export default {
  components: {
    GameCard,
  },
  
  props: {
    cards: {
      type: Array,
      required: true,
    },
    palette: {
      type: String,
      required: false,
    },
  },

  computed: {
    iPad () {
      return (window.screen.width/window.screen.height > 0.65 && window.screen.width/window.screen.height < 0.9 ) ? true : false;
    },
    cardsMobile () {
      return (Cookies.get("mobile") === "false") ? false : true;
    },
  },

  mounted () {
  },
};
</script>

<style lang="scss" scoped>
.cards {
  position: relative;
  display: flex;
  margin: 50px;
  width: 100%;
  justify-content: center;
}

.cardsMobile {
  width: 75%;
  margin: 15%;
}

.cardsIpad {
  width: 50% !important;
  margin: 25% !important;
  margin-top: 5% !important;
}
</style>